export const spacing = {
  s0: '2px',
  s1: '4px',
  s2: '8px',
  s3: '12px',
  s4: '16px',
  s5: '20px',
  s6: '24px',
  s7: '28px',
  s8: '32px',
  s9: '36px',
  s10: '40px',
};
